// SIMPLE GRID - SASS/SCSS

// fonts
$font-family: 'Roboto', sans-serif;
$font-family-alt: 'Oswald', sans-serif;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-heavy: 500;
$font-height: 1.5;

// colors
$dark-grey: #222232;
$dark-gray: #333447; // for the Americans
$light-grey: #999;
$silver: #fafafa;
$dark-silver: #f9f9f9;
$star-pink: #FF536A;