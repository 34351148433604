// universal

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
  font-size: 90%;
  scroll-behavior: smooth;
}

* {
  font-family: $font-family;
  color: $dark-grey;
  line-height: $font-height;
}

// typography

h1 {
  font-size: 2.5rem;
  font-family: $font-family-alt;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.375rem;
}

h4 {
  font-size: 1.125rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.875rem;
}

p {
  // font-size: 1.125rem;
  font-weight: 100;
  line-height: 1.8;
}

.font-light {
  font-weight: $font-weight-light;
}

.font-regular {
  font-weight: $font-weight-regular;
}

.font-heavy {
  font-weight: $font-weight-heavy;
}

// utility

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.justify {
  text-align: justify;
}

.hidden-sm {
  display: none;
}


