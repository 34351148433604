.boardpage {
    padding-top: 4rem;
}

.product-images {
    // outline: 1px solid pink;
    // max-width: 80%;
    img {
        margin-bottom: 2rem;
    }
}


.product-details {
    padding-bottom: 3rem;
    // outline: 1px solid pink;

    h1 {
        font-size: 3.5rem;
        line-height: 1.1;
        margin: 0;
    }

    h2 {
        font-size: 1.5rem;
        line-height: 1.1;
        margin: 2rem 0;
        font-weight: normal;
    }

    p {
        width: 85%;
    }

}

.attrib {
    margin-top: 2rem;

    > p {
        font-size: 0.85rem;
        font-weight: 300;
    }
}

.board-for {
    // margin-right: 15%;
    display: flex;
    justify-content: space-between;
    border: 3px solid $dark-grey;
    border-radius: 0.25rem;
    padding: 1.15rem 2rem 1.15rem 3.5rem;
    background: url('../Images/person.png') no-repeat 1.25rem 1.25rem;
    background-size: 1.25rem auto;
    font-size: 1.25rem;
    line-height: 1;

    span {
        display: block;
        font-weight: 300;
        font-size: 0.75rem;
        color: $light-grey;
        line-height: 1;
    }
}

.board-sizes {
    display: flex;
    align-items: center;

    .size {
        height: 4.5rem;
        width: 4.5rem;
        border: 1px solid $light-grey;
        border-radius: 0.25rem;
        margin-right: 0.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
        line-height: 1;

        &.selected {
            border: 3px solid $dark-grey;
        }

        span {
            display: block;
            font-weight: 300;
            font-size: 0.75rem;
            color: $light-grey;
        }
    }
}

.board-prices {
    // margin-right: 15%;
    border: 3px solid $dark-grey;
    padding: 1.25rem 1.5rem 0.75rem 1.5rem;
    border-radius: 0.25rem;

    .price {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 400;
        padding-bottom: 1.25rem;
        font-size: 120%;

        var {
            display: block;
            font-style: normal;
            font-size: 0.85rem;
            font-weight: lighter;
        }

        > a {
            margin-left: 1.5rem;
            // border: 1px solid $star-pink;
            background: $star-pink;
            border-radius: 0.25rem;
            text-decoration: none;
            padding: 0.25rem 0.75rem;
            color: white;
            font-size: 1rem;

            &:hover {
                background: $dark-grey;
            }
        }

        &:first-child {
            margin-bottom: 1rem;
            position: relative;

            &:before {
                position: absolute;
                content: '$';
                color: white;
                background: $dark-grey;
                height: 2rem;
                width: 2rem;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                left: calc(-2.5rem - 1px);
                font-family: $font-family-alt;
                font-weight: bold;
            }

            span {
                font-size: 1.5rem;
                font-weight: bold;
                font-family: $font-family-alt;
            }
        }
    }
}

.price-alerts {
    display: flex;
    background: $dark-grey;
    color: white;
    text-decoration: none;
    padding: 1rem 1.5rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.25rem;
    text-transform: uppercase;
     
    svg {
        stroke: white;
        margin-right: 1rem;
    }

    &:hover {
        background: $star-pink;
    }
}

.tabbed {
    padding: 6rem 0;
    margin-top: 5rem;
    // border-top: 1px solid $light-grey;

    .tab {
        padding: 0 1rem;

        h3 {
            font-size: 1.25rem;
            border-bottom: 3px solid white;
            padding-bottom: 1rem;
            margin-bottom: 2rem;
        }

        ul {
            padding-left: 1rem;

            li {
                color: white;
                font-weight: 100;
                line-height: 1.8;
            }
        }
    }
}


.tab-bg {
    background: $dark-grey;
    
    h3, p {
        color: white;
    }
}

.related-bg {
    padding: 6rem 0;
    background: $silver;

    h3 {
        text-align: center;
        margin:0 0  4rem 0;
        font-weight: 400;
        color: $star-pink;
        font-size: 0.85rem;
    
        @media only screen and (min-width: $breakpoint-large) {
            font-size: 1.5rem;
        }
    }
}



