header {
    position: sticky;
    top: 0;
    z-index: 10000;
    background: white;
}

nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 4rem;
    border-bottom: 1px solid $light-grey;

    .logo {
        font-weight: 700;
        font-size: 1.25rem;
        
        a {
            text-decoration: none;
            color: $star-pink;

            &:hover {
                color: $star-pink;
            }
        }
    }

    span {
        font-size: 0.85rem;
        color: $light-grey;
        font-weight: 100;
    }
}