footer {
    padding: 4rem 0 6rem 0;
    background: $dark-grey;

    .footer-copy {
        text-align: center;
        color: $light-grey;

        h2 {
            color: $star-pink;
            margin-bottom: 0;
        }

        p {
            color: $light-grey;
            font-size: 1.25rem;
            padding: 0 2rem;
        }
    }

    .mailForm {
        text-align: center;
        width: 90%;
        margin: auto;

        @media only screen and (min-width: $breakpoint-large) {
            width: 50%;
        }

        .pageAnchor {
            pointer-events: none;
        }

        >div {
            >div {
                font-size: 1.25rem;
                padding-bottom: 1rem;
                color: $star-pink !important;
            }
        }

        label {
            visibility: hidden;
            display: block;
        }

        input[type="email"] {
            padding: 1rem;
            width: 90%;
            border-radius: 0.5rem;
            margin-bottom: 0.5rem;
            border: none;
            text-align: center;
            font-family: $font-family;
            font-size: 1.25rem;
            font-weight: normal;
            display: block;
            margin: 0 auto 1rem auto;

            &:focus {
                border: 1px solid $star-pink;
            }
        }

        button {
            padding: 1rem;
            width: 90%;
            border: none;
            border-radius: 0.5rem;
            font-size: 1.25rem;
            text-transform: uppercase;
            background: $star-pink;

            &:hover {
                color: white;
                cursor: pointer;
            }
        }
    }

    .outofbounds {
        position: absolute; 
        left: -5000px;
    } 
}