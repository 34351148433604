.boardLink {
    text-decoration: none;
}

.board-tile {
    background: white;
    border-radius: 0.5rem;
    border: 3px solid white;
    transition: all 300ms ease-in-out;
    margin-bottom: 2.5rem;

    .board-brand {
        text-align: center;
        padding: 3rem 2rem 0 2rem;
        font-size: 1rem;
        color: $light-grey;
        font-weight: 300;
        // letter-spacing: 0.5px;
    }

    .board-name {
        text-align: center;
        font-family: $font-family-alt;
        font-size: 1.5rem;
        font-weight: 300;
        padding: 0.5rem 2rem 0.5rem 2rem;
    }

    .best-price {
        text-align: center;
        font-size: 1.25rem;
        width: 50%;
        padding: 0.5rem 1rem;
        margin: 0.5rem auto 2rem auto;
        border: 2px solid $dark-grey;
        border-radius: 0.25rem;
        position: relative;
        font-family: "Oswald", sans-serif;

        &::before {
            position: absolute;
            content: '$';
            color: white;
            background: #222232;
            height: 2rem;
            width: 2rem;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            left: calc(-1rem - 1px);
            font-family: "Oswald", sans-serif;
        }
    }

    .board-lvl-box {
        // max-width: 22rem;
        // padding: 0 1rem;
        margin: 0 auto;
        height: 0;
        overflow: hidden;
        transform: translateY(2rem);
        transition: all 300ms ease-in-out;
        background: $dark-silver;

        .board-for {
            border: none;
            justify-content: center;
            background-position: calc(50% - 3.25rem) 1.25rem;

            .height, .weight {
                display: none;
            }
        }
    }

    .board-img {
        overflow: hidden;
        position: relative;
        width: 100%;
        height: 40rem;
        transition: all 300ms ease-in-out;

        img {
            position: absolute;
            width: 200%;
            left: -50%;
            transition: all 300ms ease-in-out;
            transform: scale(1) translateY(0);
            // object-fit: cover;
            // object-position: center top;
            // width: 200%;
            // height: 400px;
        }
    }

    &:hover {
        cursor: pointer;
        border: 3px solid $dark-grey;

        .board-lvl-box {
            height: 5.5rem;
            display: block;
            transform: translateY(0);
        }

        .board-img {
            height: 34.5rem;
            overflow: hidden;

            img {
                transform: scale(1.3) translateY(5rem);
            }
        }
    }
}