.masthead {
    text-align: center;
    padding: 2.5rem 0 4rem 0;

    @media only screen and (min-width: $breakpoint-large) {
        font-size: 5rem;
    }
    

    h1 {
        // font-size: 5rem;
        font-family: $font-family-alt;
        margin: 0 0 1rem 0;
    
        @media only screen and (min-width: $breakpoint-large) {
            font-size: 5rem;
        }
    }

    p {
        font-size: 1.5rem;
        max-width: 50rem;
        margin: 0 auto;
        line-height: 1.3;
    
        @media only screen and (min-width: $breakpoint-large) {
            font-size: 2rem;
        }
    }

    .price-alerts {
        display: inline-flex;
        margin-top: 2rem;
        margin-bottom: 2rem;
        padding-left: 1.5rem ;
        padding-right: 1.5rem ;
        font-size: 1rem;
        border: 3px solid $dark-grey;
        background: white;
        color: $dark-grey;
    
        @media only screen and (min-width: $breakpoint-large) {
            font-size: 1.25rem;
            padding-left: 4rem ;
            padding-right: 4rem ;
        }

        svg {
            stroke: $dark-grey;
        }

        &:hover {
            border: 3px solid $star-pink;
            color: $star-pink;

            svg {
                stroke: $star-pink;
            }
        }
    }

    .scolldown {
        display: inline-block;
        font-size: 1.15rem;
        color: $star-pink;
        position: relative;
        margin-bottom: 1rem;
        margin-top: 0;
        // text-decoration: none;
    
        @media only screen and (min-width: $breakpoint-large) {
            font-size: 1.25rem;
            margin-bottom: 2rem;
            margin-top: 1rem;
        }

        svg {
            stroke: $star-pink;
            position: absolute;
            left: -1.75rem;
            top: 0.15rem;
        }

        &:hover {
            color: $dark-grey;
            cursor: pointer;
            
            svg {
                stroke: $dark-grey;
            }
        }
    }
}

.benefits {
    display: flex;
    padding: 4rem 0 6rem 0;
    border-top: 1px solid $light-grey;
    position: relative;
    flex-direction: column;

    @media only screen and (min-width: $breakpoint-large) {
        flex-direction: row;
    }

    &:before {
        top: -1.25rem;
        left: calc(50% - 80px);
        width: 160px;
        height: 2.5rem;
        line-height: 2.125rem;
        position: absolute;
        content: 'COMING SOON';
        padding: 0 1rem;
        border-radius: 2rem;
        background: $star-pink;
        color: white;
        border: 4px solid white;
        text-align: center;
        box-sizing: border-box;
        font-weight: bold;
        letter-spacing: 1px;
    }

    
    .benefit {
        padding: 0 1.25rem 4rem 1.25rem;
        text-align: center;

        @media only screen and (min-width: $breakpoint-large) {
            flex-direction: row;
            width: 25%;
            box-sizing: border-box;
            text-align: left;
        }

        h3 {

            @media only screen and (min-width: $breakpoint-large) {
                padding-right: 4rem;
            }
        }

        svg {
            stroke: $star-pink;
            height: auto;
            width: 3rem;
        }

        p {
            font-size: 1.5rem;
            max-width: 50rem;
            margin: 0 auto;
            line-height: 1.5;
        
            @media only screen and (min-width: $breakpoint-large) {
                font-size: 1.125rem;
            }
        }

    }
}

.home-boards {
    padding: 4rem 0;
    background: $silver;

    .home-anchor {
        visibility: hidden;
    }

    h3 {
        text-align: center;
        margin:0 3rem  2rem 3rem;
        font-weight: 400;
        color: $star-pink;
        font-size: 1.25rem;
    
        @media only screen and (min-width: $breakpoint-large) {
            font-size: 1.5rem;
        }
    }
}